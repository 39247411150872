import { uniqBy, flatMap, filter, get } from 'lodash'

/**
 * Group array of objects by nested Object
 *
 * @param {object[]} items Items to group by object
 * @param {string} path Object path used to group by
 * @param {string} itemsFieldName Name for nested items
 * @returns {object[]} Grouped items
 */
const groupByObject = (items, path, itemsFieldName = 'items') => {

	const [ obj, field ] = path.split(/\.(.+)/)

	const groups = uniqBy(flatMap(items, obj), field)

	return groups.map(group => {
		const nestedItems = filter(items, item => get(item, path) === group[field])

		return {
			...group,
			[itemsFieldName]: nestedItems,
		}
	})
}

export {
	groupByObject,
}
