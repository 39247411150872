<template>
	<v-container>

		<!-- TODO: group results in some beatiful way -->
		<!-- TODO: Show only user's courses, not all. Rework lessons list -->
		<v-card
			v-if="user"
			elevation="0">
			<v-card-title>{{ user.name }} {{ user.surname }}</v-card-title>
			<v-card-text>
				<p>Зареєстровано: {{ user.created_at | datetime }}</p>

				<file-downloader
					:name="`Звіт - ${user.full_name} - ${datetime}.csv`"
					:api-call="() => User.report(user.id)"
					class="text-none">
					<template>
						<v-icon left>
							mdi-file-download
						</v-icon>
						Завантажити звіт
					</template>
				</file-downloader>

				<v-data-table
					:headers="headers"
					:items="results"
					:items-per-page="15"
					:search="search"
					:loading="loading"
					loading-text="Завантаження результатів... Зачекайте, будь ласка"
					no-data-text="Немає жодного результату"
					:item-class="() => 'cursor-pointer'"
					@click:row="routeToResult">
					<template #top>
						<v-toolbar color="transparent" flat>
							<v-spacer></v-spacer>

							<v-text-field
								v-model="search"
								append-icon="mdi-magnify"
								label="Пошук"
								single-line
								hide-details
								outlined
								dense>
							</v-text-field>
						</v-toolbar>
					</template>

					<template #item.created_at="{ value }">
						{{ value | datetime }}
					</template>
				</v-data-table>
				
			</v-card-text>
		</v-card>

		<v-skeleton-loader
			v-else
			type="article">
		</v-skeleton-loader>

		<h2 class="title mt-10 mb-6">Курси</h2>

		<v-expansion-panels
			v-model="panel"
			class="mt-6"
			accordion>
			<v-expansion-panel
				v-for="(course, key) in courses"
				:key="key">
				<v-expansion-panel-header
					:color="panel === key ? 'success' : ''">
					{{ course.name }}
				</v-expansion-panel-header>

				<v-expansion-panel-content>
					<LessonsTable
						:lessons="course.lessons"
						@update-lessons="updateLessons">
					</LessonsTable>
				</v-expansion-panel-content>
			</v-expansion-panel>
		</v-expansion-panels>

	</v-container>
</template>

<script>
/* eslint-disable camelcase */
import { mapGetters, mapActions } from 'vuex'
import { groupByObject } from '../utils'

import User from '../models/User'
import Result from '../models/Result'

import LessonsTable from '../components/LessonsTable.vue'

export default {
	components: {
		LessonsTable,
	},
	props: {
		userid: {
			type: Number,
			required: true,
		},
	},
	data: () => ({
		User,
		headers: [
			{
				text: 'Test',
				value: 'test.name',
			},
			{
				text: 'Результат',
				value: 'result',
			},
			{
				text: 'Дата складання',
				value: 'created_at',
			},
		],
		loading: false,
		search: '',
		panel: null,
	}),
	computed: {
		...mapGetters('user', [
			'user',
			'lessons',
		]),
		results() {
			return Result.all()
		},
		courses() {
			return groupByObject(this.lessons, 'course.id', 'lessons')
		},
		datetime() {
			const today = new Date()
			return this.$options.filters?.datetime(today.toISOString())
		},
	},
	methods: {
		...mapActions('user', [
			'loadUser',
			'loadLessons',
			'attachLesson',
			'detachLesson',
		]),
		async updateLesson({ id, is_belongs }) {

			const data = {
				userid: this.userid,
				lesson_id: id,
			}

			if (is_belongs) await this.attachLesson(data)
			else await this.detachLesson(data)
		},

		async updateLessons({ ids, is_belongs }) {
			await Promise.all(ids.map(id => this.updateLesson({ id, is_belongs })))
			await this.loadLessons(this.userid)
		},

		async loadResults(userid) {
			try {
				await Result.fetchGetAllByUser(userid)
			} catch (error) {
				this.notify({
					text: 'Не вдалося завантажити результати учня',
					color: 'error',
				}, { root: true })
			}
		},

		/**
		 * Go to Result page
		 * @param {Object} result Result model
		 */
		routeToResult({ id }) {
			return this.$router.push({
				name: 'result',
				params: {
					id,
				},
			})
		},
	},
	mounted() {
		this.loadUser(this.userid)
		this.loadLessons(this.userid)
		this.loadResults(this.userid)
	},
}
</script>
