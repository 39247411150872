<template>
	<v-data-table
		v-model="selected"
		:headers="headers"
		:items="lessons"
		:items-per-page="8"
		:loading="loading"
		:footer-props="{ itemsPerPageOptions }"
		show-select>

		<template #header.is_belongs>
			<v-switch
				:input-value="isAllSelectedBelongs"
				:disabled="!isSelected"
				inset
				@click.stop=""
				@change="(is_belongs) => updateLessons(selectedIds, is_belongs)">
			</v-switch>
		</template>

		<template #item.is_belongs="{ item }">
			<v-switch
				v-model="item.is_belongs"
				inset
				@change="updateLesson(item)">
			</v-switch>
		</template>

	</v-data-table>
</template>

<script>
/* eslint-disable camelcase */
export default {
	props: {
		lessons: {
			type: Array,
			default: () => ([]),
		},
	},
	data: () => ({
		headers: [
			{
				text: 'Номер',
				value: 'order',
			},
			{
				text: 'Назва заняття',
				value: 'name',
			},
			{
				text: 'Призначення',
				value: 'is_belongs',
			},
		],
		selected: [],
		loading: false,
		itemsPerPageOptions: [8, 16, -1],
	}),
	computed: {
		isSelected() {
			return this.selected.length > 0
		},
		selectedIds() {
			return this.selected.map(({ id }) => id)
		},
		isAllSelectedBelongs() {
			if (this.isSelected) {
				return this.selected.every(({ is_belongs }) => is_belongs)
			} else {
				return false
			}
		},
	},
	methods: {
		updateLesson({ id, is_belongs }) {
			return this.updateLessons([ id ], is_belongs)
		},

		updateLessons(ids, is_belongs) {
			return this.$emit('update-lessons', {
				ids,
				is_belongs,
			})
		},
	},
}
</script>
